/*global document, window, jQuery, Foundation*/

jQuery(document).ready(function ($) {
    'use strict';

    if (!('ontouchstart' in document.documentElement)) {
        document.documentElement.className += ' no-touch';
    }

    // Only execute when we are on a touch device
    if (!$('html').hasClass('no-touch')) {
        $('.ImagePanel--hoverImage').on('touchstart', function (e) {
            // If the parent hasn't been clicked before, add the class and prevent the event, otherwise do nothing.
            if (!$(this).hasClass('ImagePanel--hover')) {
                e.preventDefault();
                $(this).addClass('ImagePanel--hover');
            }
        });

        // When clicking anywhere else on the document, remove class
        $('html').on('touchstart', function (e) {
            if ($(e.target).parents('[class^="small-block-grid"] .columns').length === 0) {
                if ($('.ImagePanel--hoverImage').hasClass('ImagePanel--hover')) {
                    $('.ImagePanel--hoverImage').removeClass('ImagePanel--hover');
                }
            }
        });
    }
});
