(function () {
    var el = document.getElementById('js-flowbox-flow'),
        tags;

    if (el) {
        if (el.getAttribute('data-tags')) {
            var getTags = el.getAttribute('data-tags').replace(/["']/g, '');
            tags = getTags.split(',');
        }

        window.flowbox('init', {
            container: el,
            key: el.getAttribute('data-key'),
            locale: el.getAttribute('data-locale'),
            tags: tags,
            tagsOperator: 'any', // Display posts that have any of the tags
        });
    }
})();
