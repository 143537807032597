/*global jQuery, document, window, Foundation*/
(function ($) {
    'use strict';

    var $itemScrollers = $('.itemScroller'),
        sliders = [],
        Slider;

    if ($itemScrollers.length === 0) {
        return;
    }

    Slider = function ($container) {
        var self = this,
            transitionValue;

        self.screenWidth = $(window).width();
        self.$container = $container;
        self.$scrollContainer = self.$container.find('.itemScroller-scrollContainer');
        self.$items = $container.find('.itemScroller-item');
        self.itemsWithPositionsAndWidths = [];
        self.currentPosition = 0;
        self.mostRightPosition = undefined;
        self.transitionSpeed = 0;

        transitionValue = this.$scrollContainer.css('transition');

        if (transitionValue !== undefined && transitionValue !== null && transitionValue !== '') {
            self.transitionSpeed = parseFloat(transitionValue.match(/(\d+\.?\d*)s/)[1]) * 1000;
        }
    };

    Slider.prototype.initialize = function () {
        var prevLink = $('<a href="#" title="Terug scrollen">Terug scrollen</a>'),
            nextLink = $('<a href="#" title="Verder scrollen">Verder scrollen</a>');

        prevLink.addClass('itemScroller-control itemScroller-control--prev');
        nextLink.addClass('itemScroller-control itemScroller-control--next');

        this.$container.append(prevLink, nextLink);

        this.calculateItemPositions();
        this.calculateMostRightPosition();
    };

    Slider.prototype.calculateItemPositions = function () {
        var itemCount = this.$items.length,
            i,
            currentItem;

        this.screenWidth = $(window).width();
        this.itemsWithPositionsAndWidths = [];

        for (i = 0; i < itemCount; i += 1) {
            currentItem = this.$items.filter(':eq(' + i + ')');
            this.itemsWithPositionsAndWidths.push({
                item: currentItem,
                leftOffset: currentItem.offset().left,
                width: currentItem.outerWidth(),
            });
        }
    };

    Slider.prototype.calculateMostRightPosition = function () {
        this.mostRightPosition = Math.max.apply(
            null,
            $.map(this.itemsWithPositionsAndWidths, function (item) {
                return item.leftOffset + item.width;
            }),
        );
    };

    Slider.prototype.slideToStart = function () {
        this.slideTo(0);
    };

    Slider.prototype.slideBackward = function () {
        var self = this,
            newPosition,
            partlyVisibleElements = [];

        if (self.currentPosition === 0) {
            self.slideTo(-50);

            window.setTimeout(function () {
                self.slideTo(0);
            }, 200);
        } else {
            $.each(self.itemsWithPositionsAndWidths, function () {
                var currentItem = this;

                if (
                    currentItem.leftOffset < self.currentPosition &&
                    currentItem.leftOffset + currentItem.width > self.currentPosition
                ) {
                    partlyVisibleElements.push(currentItem.leftOffset + currentItem.width - self.screenWidth);
                }
            });

            if (partlyVisibleElements.length > 0) {
                newPosition = Math.max.apply(null, partlyVisibleElements);
            } else {
                newPosition = Math.max.apply(
                    null,
                    self.itemsWithPositionsAndWidths
                        .map(function (item) {
                            return item.leftOffset < self.currentPosition
                                ? item.leftOffset + item.width - self.screenWidth
                                : null;
                        })
                        .filter(function (value) {
                            return value !== null;
                        }),
                );
            }

            if (newPosition < 0) {
                newPosition = 0;
            }

            self.slideTo(newPosition);
        }
    };

    Slider.prototype.slideForward = function () {
        var self = this,
            newPosition,
            partlyVisibleElements = [];

        $.each(self.itemsWithPositionsAndWidths, function () {
            var currentItem = this;

            if (
                currentItem.leftOffset < self.currentPosition + self.screenWidth &&
                currentItem.leftOffset + currentItem.width > self.currentPosition + self.screenWidth
            ) {
                partlyVisibleElements.push(currentItem.leftOffset);
            }
        });

        if (partlyVisibleElements.length > 0) {
            newPosition = Math.min.apply(null, partlyVisibleElements);
        } else {
            newPosition = Math.min.apply(
                null,
                self.itemsWithPositionsAndWidths
                    .map(function (item) {
                        return item.leftOffset >= self.currentPosition + self.screenWidth ? item.leftOffset : null;
                    })
                    .filter(function (value) {
                        return value !== null;
                    }),
            );
        }

        if (newPosition + this.screenWidth > this.mostRightPosition) {
            newPosition = this.mostRightPosition - this.screenWidth;
        }

        if (newPosition === this.currentPosition) {
            window.setTimeout(function () {
                self.slideTo(self.currentPosition - 50);
            }, 200);

            newPosition = this.currentPosition + 50;
        }

        self.slideTo(newPosition);
    };

    Slider.prototype.slideTo = function (position) {
        this.currentPosition = position;
        window.setTransformTranslate(this.$scrollContainer, position * -1 + 'px');
    };

    $(document).ready(function ($) {
        var itemScrollerDetailPanels = $('.itemScroller-detail');

        $itemScrollers.each(function () {
            var slider = new Slider($(this));
            slider.initialize();

            $(this).data('itemScroller', slider);

            sliders.push(slider);
        });

        $('.itemScroller-control').on('click.MaxServ.ItemScroller', function (event) {
            event.preventDefault();

            var slider = $(this).parents('.itemScroller').data('itemScroller');

            if ($(this).hasClass('itemScroller-control--prev')) {
                slider.slideBackward();
            } else {
                slider.slideForward();
            }
        });

        $('.itemScroller-viewDetailLink').on('click.MaxServ.ItemScroller', function (event) {
            event.preventDefault();

            var slider = $(this).parents('.itemScroller').data('itemScroller'),
                detailPanel = $(this).parents('.itemScroller-row:first').find('.itemScroller-detail'),
                panelIsVisible = detailPanel.hasClass('js-is-visible'),
                detailPanelLeftOffset,
                detailPanelWidth,
                newPosition;

            itemScrollerDetailPanels.removeClass('js-is-visible');
            if (panelIsVisible === false) {
                detailPanel.addClass('js-is-visible');

                detailPanelLeftOffset = detailPanel.offset().left;
                detailPanelWidth = detailPanel.width();
                if (detailPanelLeftOffset + detailPanel.width() > slider.screenWidth) {
                    newPosition = slider.currentPosition + detailPanelWidth;

                    if (detailPanelLeftOffset + newPosition > slider.screenWidth + detailPanelWidth) {
                        newPosition = newPosition + detailPanelWidth;
                    }

                    slider.slideTo(newPosition);
                }
            }
        });

        $(window).on(
            'load.MaxServ.ItemScroller resize.MaxServ.ItemScroller',
            Foundation.util.throttle(function () {
                var windowHeight = $(window).height();

                $itemScrollers.each(function () {
                    var typeAHeight = $(this).find('.itemScroller-item--typeA:first').height(),
                        typeBHeight = $(this).find('.itemScroller-item--typeB:first').height(),
                        typeGHeight = $(this).find('.itemScroller-item--typeG:first').height();

                    if (
                        (typeAHeight !== null && typeAHeight + typeBHeight > windowHeight) ||
                        (typeGHeight !== null && typeGHeight * 2 > windowHeight)
                    ) {
                        $(this).addClass('itemScroller--singleRow');
                    } else {
                        $(this).removeClass('itemScroller--singleRow');
                    }
                });

                $.each(sliders, function () {
                    var currentSlider = this;
                    currentSlider.slideToStart();

                    window.setTimeout(function () {
                        currentSlider.calculateItemPositions();
                        currentSlider.calculateMostRightPosition();
                    }, currentSlider.transitionSpeed);
                });
            }, 500),
        );
    });
})(jQuery);
