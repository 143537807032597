/*global jQuery*/
jQuery(function ($) {
    'use strict';
    function initProductBlockSlider() {
        // Always unslick the slider here,
        $('.ProductBlock-slider.slick-slider').slick('unslick');

        if (Foundation.MediaQuery.atLeast('large') === false) {
            $('.ProductBlock-slider').slick({
                autoplay: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 769,
                        settings: {
                            arrows: false,
                            slidesToShow: 2,
                        },
                    },
                ],
            });
        }
    }

    function debounceSlick(func, wait) {
        let timeout;

        return function () {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(), wait);
        };
    }

    const debouncedResize = debounceSlick(initProductBlockSlider, 300);

    window.addEventListener('resize', debouncedResize);

    initProductBlockSlider();
});
