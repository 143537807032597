/*global window, document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    $(document).on('click', '.calculate-route input', function () {
        window.setTimeout(function () {
            $('html,body').animate(
                {
                    scrollTop: $('.places-route').offset().top - $('.pageHeader').outerHeight(),
                },
                500,
            );
        }, 500);
    });
});
