/*global jQuery,document*/
jQuery(document).ready(function ($) {
    'use strict';

    var venueToggle = $('.province .province-name');

    venueToggle.on('click', function () {
        if (Foundation.MediaQuery.atLeast('large') === false) {
            venueToggle.not($(this)).removeClass('is-open');
            $(this).toggleClass('is-open');
        }
    });
});
