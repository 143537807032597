/*global document, event,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    var $searchForm = $('.searchForm'),
        $searchFormButton = $('.js-toggleSiteSearch'),
        $searchFormClose = $('.searchForm-close'),
        $searchFormLookUp = $('.searchForm-lookup');

    $searchFormButton.on('click.MaxServ.SiteSearch', function (event) {
        event.preventDefault();

        if ($searchFormLookUp.length > 0) {
            return false;
        }

        $(this).toggleClass('is-active');

        $('html, body').animate({ scrollTop: 0 });
        $searchForm.not('.searchForm--lookup').slideToggle();
    });

    $searchFormClose.on('click.MaxServ.SiteSearch', function (event) {
        event.preventDefault();

        $searchFormButton.removeClass('is-active');
        $(this).parents('.searchForm').slideToggle();
    });
});
