/*global window,document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    var slider = $('.js-sliderHybrid'),
        prevSlide = $('.js-sliderHybrid-prev'),
        nextSlide = $('.js-sliderHybrid-next');

    prevSlide.on('click', function () {
        $(this).parents('.js-has-sliderHybrid').find(slider).slick('slickPrev');
    });

    nextSlide.on('click', function () {
        $(this).parents('.js-has-sliderHybrid').find(slider).slick('slickNext');
    });

    slider.on('afterChange', function (event, slick, currentSlide) {
        var parentElement = $(this).parents('.js-has-sliderHybrid'),
            numberOfSlides = slick.$slides.length,
            lastArrowState = 1;

        if (numberOfSlides % 2 === 0) {
            // if number of slides is an even number:
            lastArrowState = 2;
        }

        if (numberOfSlides - lastArrowState === currentSlide) {
            parentElement.find(nextSlide).addClass('is-disabled');
        } else if (currentSlide === 0) {
            parentElement.find(prevSlide).addClass('is-disabled');
        } else if (!(numberOfSlides - lastArrowState === currentSlide) || !(currentSlide === 0)) {
            parentElement.find(prevSlide).removeClass('is-disabled');
            parentElement.find(nextSlide).removeClass('is-disabled');
        }
    });
});
